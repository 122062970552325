<!-- Generic client form for adding or editing a client -->
<template>
  <div>
    <v-snackbar
      v-model="snackbar"
      class="snackbar"
      :timeout="notice.timeout"
      top
      color="white"
    >
      <img
        v-if="notice.type === 'info'"
        class="icon--success"
        src="@/assets/icon-check--green.svg"
        alt="Success checkmark"
      >
      <img
        v-if="notice.type === 'error' || notice.type === 'err'"
        class="icon--error"
        src="@/assets/icon-error.svg"
        alt="Error icon"
      >
      <span class="snackbar--text">{{ notice.message }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="black"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          <img
            class="icon--close"
            src="@/assets/icon-close.svg"
            alt="Icon to close dialog"
          >
        </v-btn>
      </template>
    </v-snackbar>
    <v-container class="main">
      <v-form
        ref="contactForm"
        v-model="contactFormValid"
        @submit.prevent="saveContact"
      >
        <v-row
          class="mt-12"
          no-gutters
        >
          <v-col
            cols="12"
            class="pb-4"
          >
            <h3>Client Info</h3>
            <p>Please enter your client's information</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
          >
            <v-select
              v-model="client.title"
              class="custom-text-field"
              filled
              :height="'64'"
              :items="titles"
              label="Title"
            />
          </v-col>
          <v-col
            cols="12"
          >
            <!-- If firstName doesn't exist -->
            <form-input
              v-if="
                clientState === 'Selected client' &&
                  !client.firstName"
              v-model="(client.name).split(' ')[0]"
              label="First name*"
              :rules="rules.name"
              validate-on-blur
              autocomplete="given-name"
            />
            <!-- Else -->
            <form-input
              v-else
              v-model="client.firstName"
              label="First name*"
              :rules="rules.name"
              validate-on-blur
              autocomplete="given-name"
            />
          </v-col>
          <v-col
            cols="12"
          >
            <!-- If lastName doesn't exist and full name was used -->
            <form-input
              v-if="
                clientState === 'Selected client' &&
                  !client.lastName && 
                  client.name.split(' ').length > 1"
              v-model="client.name.split(' ')[client.name.split(' ').length-1]"
              label="Last name"
              validate-on-blur
            />
            <!-- If lastName doesn't exist and only the first name was used -->
            <form-input
              v-else-if="
                clientState === 'Selected client' &&
                  !client.lastName && 
                  client.name.split(' ').length == 1"
              v-model="client.lastName"
              label="Last name"
              validate-on-blur
              autocomplete="family-name"
            />
            <!-- Else -->
            <form-input
              v-else
              v-model="client.lastName"
              label="Last name"
              validate-on-blur
              autocomplete="family-name"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
          >
            <form-input
              v-model="client.phone"
              label="Phone"
              type="phone"
              :rules="rules.phone"
              validate-on-blur
              :max-length="20"
              autocomplete="tel"
              @input="isPhoneExists = false"
            />
            <form-input 
              v-model="client.email"
              label="Email"
              type="email"
              :rules="rules.email"
              validate-on-blur
              autocomplete="email"
              @input="isEmailExists = false"
            />
            <p>Preferred communication</p>
            <v-radio-group
              v-model="client.channel"  
            >
              <v-radio 
                label="Phone"
                value="sms"
                @change="channelChange('sms')"
              />
              <v-radio 
                label="Email"
                value="email"
                @change="channelChange('email')"
              />
            </v-radio-group>
            <!-- Extra information -->
          </v-col>
          <v-col
            cols="12"
          >
            <div v-if="!showAdditionalClientInfo">
              <v-btn
                class="button-primary"
                block
                x-large
                outlined
                @click="showAdditionalClientInfo = true"
              >
                + additional information
              </v-btn>
            </div>
            <div v-if="showAdditionalClientInfo">
              <form-date-picker
                v-model="client.dob"
                label="Date of birth"
              />
              <v-select
                v-model="client.gender"
                class="custom-text-field"
                filled
                :height="'64'"
                :items="genders"
                label="Gender"
                item-text="text"
                item-value="id"
              />
              <v-select
                v-model="client.race"
                class="custom-text-field"
                filled
                :height="'64'"
                :items="races"
                label="Race"
                item-text="text"
                item-value="id"
              />
              <v-select
                v-model="client.ethnicity"
                class="custom-text-field"
                filled
                :height="'64'"
                :items="ethnicities"
                label="Ethnicity"
                item-text="text"
                item-value="id"
              />
            </div>
            <v-checkbox 
              v-model="client.hasCaregiver"
              label="This client has a caregiver"
            />
          </v-col>
        </v-row>
        <div v-if="client.hasCaregiver">
          <hr>
          <v-row
            class="mt-12"
            no-gutters
          >
            <v-col
              cols="12"
              class="pb-4"
            >
              <h3>Caregiver info</h3>
              <p>Please enter your caregiver's personal information</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="client.caregiverTitle"
                class="custom-text-field"
                filled
                :height="'64'"
                :items="titles"
                label="Title"
              />
            </v-col>
            <v-col cols="12">
              <!-- If caregiverFirstName doesn't exist -->
              <form-input 
                v-if="
                  clientState === 'Selected client' &&
                    !client.caregiverFirstName &&
                    client.caregiverName"
                v-model="client.caregiverName.split(' ')[0]"
                label="First name*"
                :rules="rules.name"
                validate-on-blur
                autocomplete="given-name"
              />
              <!-- Else -->
              <form-input
                v-else
                v-model="client.caregiverFirstName"
                label="First name*"
                :rules="rules.name"
                validate-on-blur
                autocomplete="given-name"
              />
            </v-col>
            <v-col cols="12">
              <!-- If caregiverLastName doesn't exist and full name was used -->
              <form-input 
                v-if="
                  clientState === 'Selected client' &&
                    client.caregiverName &&
                    !client.caregiverLastName && 
                    client.caregiverName.split(' ').length > 1" 
                v-model="client.caregiverName.split(' ')[client.caregiverName.split(' ').length-1]"
                label="Last name*"
                :rules="rules.name"
                validate-on-blur
                autocomplete="family-name"
              />
              <!-- If caregiverLastName doesn't exist and only the first name was used -->
              <form-input 
                v-else-if="
                  clientState === 'Selected client' &&
                    client.caregiverName &&
                    !client.caregiverLastName && 
                    client.caregiverName.split(' ').length == 1"
                v-model="client.caregiverLastName"
                label="Last name*"
                :rules="rules.name"
                validate-on-blur
                autocomplete="family-name"
              />
              <!-- Else -->
              <form-input
                v-else
                v-model="client.caregiverLastName"
                label="Last name*"
                :rules="rules.name"
                validate-on-blur
                autocomplete="family-name"
              />
            </v-col>
            <v-col cols="12">
              <form-input
                v-model="client.caregiverPhone"
                label="Phone"
                :disabled="caregiverAsClient"
                autocomplete="tel"
              />
              <form-input
                v-model="client.caregiverEmail"
                label="Email"
                :disabled="caregiverAsClient"
                :rules="rules.caregiverEmail"
                autocomplete="email"
              />
              Preferred communication
              <v-radio-group
                v-model="client.caregiverChannel"
              >
                <v-radio 
                  label="Phone"
                  value="sms"
                  @change="caregiverChannelChange('sms')"
                />
                <v-radio 
                  label="Email"
                  value="email"
                  @change="caregiverChannelChange('email')"
                />
              </v-radio-group>
              <!-- Extra information -->
            </v-col>
            <v-col cols="12">
              <div v-if="!showAdditionalCaregiverInfo">
                <v-btn
                  class="form-button-outlined"
                  block
                  x-large
                  outlined
                  @click="showAdditionalCaregiverInfo = true"
                >
                  + additional information
                </v-btn>
              </div>
              <div v-if="showAdditionalCaregiverInfo">
                <form-date-picker
                  v-model="client.caregiverDob"
                  label="Date of birth"
                />
                <v-select
                  v-model="client.caregiverGender"
                  class="custom-text-field"
                  filled
                  :height="'64'"
                  :items="genders"
                  label="Gender"
                  item-text="text"
                  item-value="id"
                />
                <v-select
                  v-model="client.caregiverRace"
                  class="custom-text-field"
                  filled
                  :height="'64'"
                  :items="caregiverRaces"
                  label="Race"
                  item-text="text"
                  item-value="id"
                />
                <v-select
                  v-model="client.caregiverEthnicity"
                  class="custom-text-field"
                  filled
                  :height="'64'"
                  :items="caregiverEthnicities"
                  label="Ethnicity"
                  item-text="text"
                  item-value="id"
                />
              </div>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row
            class="mt-12"
            no-gutters
          >
            <v-col cols="12">
              <p>*This field is mandatory</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="buttons"
            >
              <v-btn
                v-if="!isClientAdd"
                class="form-button-filled button--permanently-delete"
                elevation="0"
                large
                @click="deleteClient"
              >
                Delete client
              </v-btn>

              <v-btn
                text
                class="form-button-filled button--cancel"
                color="primary"
                large
                @click="$router.go(-1)"
              >
                Cancel
              </v-btn>

              <v-btn
                class="form-button-filled button--save button-primary"
                type="submit"
                large
                elevation="0"
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </v-container>
    <v-dialog
      v-model="deleteModal"
      width="auto"
      class="dialog"
    >
      <v-card class="dialog--content">
        <v-card-text>
          <header v-if="!confirmClientDeletion">
            <h3>Are you sure you want to delete {{ client.name }}?</h3>
            <h3>All data will be lost. This action cannot be undone.</h3>
          </header>
          <header v-if="confirmClientDeletion && !clientDeleted">
            <h3>Confirm by typing client's name exactly</h3>
            <h4>*Name is case-sensitive</h4>
          </header>
          <header v-if="clientDeleted">
            <h3>Client deleted</h3>
          </header>
          <div
            v-if="!confirmClientDeletion && !clientDeleted"
            class="dialog--buttons"
          >
            <v-btn
              text
              class="form-button-filled button--cancel"
              color="primary"
              @click="deleteModal = false"
            >
              No
            </v-btn>

            <v-btn
              class="form-button-filled button--save"
              type="submit"
              elevation="0"
              @click="confirmClientDeletion = true"
            >
              Yes
            </v-btn>
          </div>
          <div
            v-if="confirmClientDeletion && !clientDeleted"
            class="dialog--confirm-client"
          >
            <v-text-field
              v-model="clientName"
              :clearable="!clientNameMatch"
              :readonly="clientNameMatch"
              label="Client name"
              :placeholder="client.name"
              @keyup="confirmClient"
            />
            <sub
              v-if="clientNameMatch"
              class="dialog--client-name-matched"
            >Client name matched</sub>
            <v-checkbox
              v-if="clientNameMatch"
              v-model="understand"
              label="I understand that deletion is permanent"
            />
            <v-btn
              v-if="understand"
              elevation="0"
              class="form-button-filled button--permanently-delete"
              @click="callSoftDeleteClient"
            >
              Permanently delete client
            </v-btn>
          </div>
          <div v-if="clientDeleted">
            <p>If you feel you've made a mistake and need to recover this client, please contact us</p>
          </div>
        </v-card-text>
        <v-card-actions class="dialog--footer">
          <v-btn
            color="primary"
            text
            @click="deleteModal = false"
          >
            <span v-if="!clientDeleted">Cancel deletion</span>
            <span v-else>Close</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import moment from '@/plugins/moment'
import emailValidation from '@/helpers/emailValidation'

// Form components
import FormDatePicker from '@/components/form/FormDatePicker'
import FormInput from '@/components/form/FormInput'

// Dictionaries
import genders from '@/kit/dictionaries/genders'
import races from '@/kit/dictionaries/races'
import ethnicities from '@/kit/dictionaries/ethnicities'
import caregiverRaces from '@/kit/dictionaries/caregiverRaces'
import caregiverEthnicities from '@/kit/dictionaries/caregiverEthnicities'

export default {
  name: 'ClientForm',
  components: {
    FormInput,
    FormDatePicker
  },
  props: {
    clientState: {
      type: String,
      required: true
    },
    clientProp: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      caregiverRaces: caregiverRaces(),
      caregiverEthnicities: caregiverEthnicities(),
      client: { ...this.clientProp },
      clientDeleted: false,
      clientName: '',
      clientNameMatch: false,
      confirmClientDeletion: false,
      contactFormValid: false,
      datePicker: false,
      deleteModal: false,
      ethnicities: ethnicities(),
      genders: genders(),
      isPhoneExists: false,
      isEmailExists: false,
      isClientAdd: false,
      moment,
      notice: {
        message: '',
        timeout: 4000,
        type: ''
      },
      races: races(),
      rules: {
        name: [v => !!v || 'Name is required'],
        phone: [
          v =>
            !this.channelSms || !!v || 'Phone is required if set as preferred',
          v =>
            !this.channelSms ||
            !v ||
            /\d{10}/.test(v.replace(/\D/g, '')) ||
            'Phone number must be valid',
          // () => !this.isPhoneExists || 'User with this phone already exists'
        ],
        email: [
          v =>
            !this.channelEmail ||
            !!v ||
            'Email is required if set as preferred',
          v => !this.channelEmail || emailValidation(v),
          // () => !this.isEmailExists || 'User with this email already exist'
        ],
        caregiverEmail: [
          v => !v || /.+@.+\..+/.test(v) || 'Email must be valid'
        ]
      },
      showAdditionalClientInfo: false,
      showAdditionalCaregiverInfo: false,
      snackbar: false,
      titles: [
        'Ms.', 
        'Mrs.', 
        'Mr.', 
        'Dr.',
        'Other'
      ],
      understand: false,
    }
  },
  computed: {
    ...mapGetters('authentication', ['user']),
    formTitle() {
      return this.client.name || 'Client name'
    },
    channelSms() {
      return this.client.channel === 'sms'
    },
    channelEmail() {
      return this.client.channel === 'email'
    },
    caregiverAsClient: {
      get() {
        return this.client.caregiverAsClient
      },
      set(value) {
        this.client.caregiverAsClient = value
      }
    },
    isUpdating() {
      return !!this.client.id
    },
    submitButtonText() {
      return this.isUpdating ? 'Update' : 'Add'
    }
  },
  watch: {
    'client.phone': function() {
      if (this.caregiverAsClient) {
        this.client.caregiverPhone = this.client.phone
      }
    },
    'client.email': function() {
      if (this.caregiverAsClient) {
        this.client.caregiverEmail = this.client.email
      }
    },
  },
  mounted() {
    this.isClientAdd = this.$route.name === 'clientAdd'
  },
  methods: {
    ...mapMutations('app', ['loading']),
    ...mapActions('clients', ['writeClient', 'getClient', 'softDeleteClient', 'resetClient']),
    ...mapActions('schedules', ['deleteScheduledActivities']),
    confirmClient() {
      if (this.clientName === this.client.name) {
        this.clientNameMatch = true
      }
    },
    async deleteClient() {
      this.deleteModal = true
    },
    async callSoftDeleteClient() {
      if (this.clientNameMatch && this.understand) {
        this.clientDeleted = true
        // delete client first
        await this.softDeleteClient({
          id: this.client.id, 
          uid: this.user.id, 
          count: this.user.countClients
        })
        // delete all scheduled activities
        await this.deleteScheduledActivities({uid: this.client.id})
        this.resetClient()
        // this.$router.push({name: 'dashboard'})
      }
    },
    setCaregiverFromClient() {
      if (this.caregiverAsClient) {
        this.client.caregiverPhone = this.client.phone
        this.client.caregiverEmail = this.client.email
      }
    },
    channelChange(name) {
      this.client.channel = name
      this.validate('contactForm')
    },
    caregiverChannelChange(name) {
      this.client.caregiverChannel = name
      this.validate('contactForm')
    },
    async validate(ref) {
      const clientEmailExists = await this.getClient({
        email: this.client.email
      })
      const clientPhoneExists = await this.getClient({
        phone: this.client.phone
      })
      
      this.isEmailExists =
      !!clientEmailExists && clientEmailExists.id !== this.client.id
      // we don't care if clients share phone numbers
      // this.isPhoneExists =
      // !!clientPhoneExists && clientPhoneExists.id !== this.client.id
      
      this.$refs[ref].validate()
    },
    async saveContact() {
      
      // Creating first and last name if they did not exist
      const FIRST_NAME = 0
      const LAST_NAME = 1
      
      if (!this.client.firstName) {
        this.client.firstName = this.client.name.split(' ')[FIRST_NAME]
      }
      if (!this.client.lastName) {
        // this.client.lastName = this.client.name.split(' ')[LAST_NAME]
        this.client.lastName = ''
      }

      // Parsing first and last name into single name field
      this.client.name = `${this.client.firstName} ${this.client.lastName}`
      
      if (this.client.hasCaregiver) {
        // Creating first name and last name for caregiver if they did not exist
        if (!this.client.caregiverFirstName) {
            this.client.caregiverFirstName = this.client.caregiverName.split(' ')[FIRST_NAME]
        }
        if (!this.client.caregiverLastName) {
            this.client.caregiverLastName = this.client.caregiverName.split(' ')[LAST_NAME]
        }

        // Parsing first and last name into single name field for caregiver
        this.client.caregiverName = `${this.client.caregiverFirstName} ${this.client.caregiverLastName}`
      }

      this.client.isDeleted = false
      
      // Validate
      await this.validate('contactForm')

      // Verify client fields, trigger loading, and add to firebase
      if (this.contactFormValid) {
        this.loading(true)
        for (const field in this.client) {
          if (this.client[field] === undefined) {
            this.client[field] = null
          }
        }
        this.writeClient(this.client)
          .then(async() => {
            const status = this.isUpdating ? 'updated' : 'created'
            const msg = 'Client was successfully ' + status
            this.notice.message = msg
            this.notice.type = 'info'
            this.snackbar = true
          })
          .catch(e =>
            this.$notify({
              type: 'err',
              text: e.message
            })
          )
          .finally(() => this.loading(false))
      }
    },
  }
}
</script>

<style lang="scss">

.main {
  margin-bottom: 10rem;
  max-width: 768px;
}
.dialog {
  font-family: var(--font-family-primary);
}
.dialog--content {
  padding: 1rem;
}

.dialog--content header {
  font-family: var(--font-family-primary);
  margin-bottom: 1rem;
}

.dialog--confirm-client {
  display: flex;
  flex-direction: column;
}

.dialog--client-name-matched {
  color: var(--v-error-darken1);
}

.button--permanently-delete {
  justify-self: center;
}

.dialog--buttons, .dialog--footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1rem;
}
.form-submit {
  display: flex;
  justify-content: space-between;
}
.col {
  padding-top: 0;
  padding-bottom: 0;
}
.buttons {
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.button--cancel {
  background-color: transparent;
}
.button--delete {
  background-color: var(--v-error-base) !important;
  color: #fff !important;
  margin-right: 2rem;
}
.button--save {
  background-color: var(--v-primary-base) !important;
  color: #fff !important;
}
.form-button-outlined {
  color: black !important;
  border-radius: 15px;
  text-transform: none;
  background-image: none !important;
  border-color: #e0e0e0 !important;
}
.form-button-filled {
  color: black;
  border-radius: 15px;
  margin-left: .5rem;
  text-transform: none;
  border: none;
  font-weight: bold;
}
.form-button-filled:first-of-type {
  margin-left: 0;
}
hr {
  margin-top: 50px !important;
  margin-bottom: 40px !important;
  border-top: #e0e0e0 !important;
}
p {
  color: #666
}
</style>