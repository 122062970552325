<template>
  <div>
    <page-header sticky>
      {{ clientState }}
    </page-header>
    <client-form 
      :client-state="clientState"
      :client-prop="client"
    />
  </div>
</template>

<script>
import defaultState from '@/store/clients/state'
import ClientForm from '@/views/dashboard/client/ClientForm'
import PageHeader from '@/components/dashboard/PageHeader'

export default {
  name: 'ClientAdd',
  components: { ClientForm, PageHeader },
  data() {
    return {
      client: { ...defaultState().client, channel: 'sms'}
    }
  },
  computed: {
    clientState() {
      // Handle condition when user comes from the "welcome" screen
      if (this.$route.query.first) {
        return 'Your first client'
      }
      return 'New client'
    }
  }
}
</script>